import { q, client } from "../helpers/init-db";

const getMyGenotype = function() {
    const me = q.Identity();
    return client.query(
        q.Map(
            q.Paginate(q.Match(q.Index("genotype_userId-idx"), q.Select(["data", "id"], q.Get(me))), { size: 1000 }),
            q.Lambda("ref", q.Get(q.Var("ref"))),
        )
      )
}

const getUserGenotype = function(userId) {
    const me = q.Identity();
    return client.query(
        q.Map(
            q.Paginate(q.Match(q.Index("genotype_userId-idx"), userId), { size: 1000 }),
            q.Lambda("ref", q.Get(q.Var("ref"))),
        )
      )
}

export {getMyGenotype, getUserGenotype}